import React, { useState } from "react";
//import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation,gql } from "@apollo/client";
import SEO from "../components/seo";
import { useForm } from "react-hook-form";

import { Box, Button, Input, Textarea } from "@chakra-ui/react";
import Nav from "../components/Nav";
import { translate } from "../utils/get-locale";

const CONTACT_MUTATION = gql`
	mutation CreateSubmissionMutation(
		$clientMutationId: String!
		$firstName: String!
		$lastName: String!
		$email: String!
		$message: String!
	) {
		createSubmission(
			input: {
				clientMutationId: $clientMutationId
				firstName: $firstName
				lastName: $lastName
				email: $email
				message: $message
			}
		) {
			success
			data
		}
	}
`;

const ParaGraph = styled.div`
	margin-top: 50px;
	p {
		font-family: "fieldwork";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
		padding-bottom: 1rem;
	}
	strong {
		font-weight: 700;
	}
`;

const ContactTemplate = ({ page }) => {
	const [firstNameValue, setFirstNameValue] = useState("");
	const [lastNameValue, setLastNameValue] = useState("");
	const [emailValue, setEmailValue] = useState("");
	const [messageValue, setMessageValue] = useState("");

	const [submitError,setSubmitError] = useState(false);
	const [submitSuccess,setSubmitSuccess] = useState(false);

	const [createPost, { loading, error }] = useMutation(CONTACT_MUTATION,{
		onCompleted: (data) => {
			setSubmitSuccess(true);
			//console.log("Data from mutation", data)
		},
		onError: (error) => {
			setSubmitError(true);
			//console.error("Error creating a post", error)
		},
	  });

	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data) => {
		setSubmitError(false);
		setSubmitSuccess(false);
		createPost({
			variables: {
				clientMutationId: "mecform",
				firstName: firstNameValue,
				lastName: lastNameValue,
				email: emailValue,
				message: messageValue,
			},
		});
	}
	return (
    <>
		<SEO title={page.seo?.title} description={page.seo?.metaDesc} />
    <Nav />
		<Box w="100%" maxW={600} mx="auto" mt={100} px={"16px"}>
			<ParaGraph dangerouslySetInnerHTML={{ __html: page.content }} />

					<React.Fragment>
						<form
 							onSubmit={handleSubmit(onSubmit)}
						>
							<label htmlFor="firstNameInput">{translate("etunimi")} *</label>
							<Input
								id="firstNameInput"
								value={firstNameValue}
								onChange={(event) => {
									setFirstNameValue(event.target.value);
								}}
								ref={register({ required: true })}
							/>

							<br />
							<br />

							<label htmlFor="lastNameInput">{translate("sukunimi")} * </label>
							<Input
								id="lastNameInput"
								value={lastNameValue}
								onChange={(event) => {
									setLastNameValue(event.target.value);
								}}
								ref={register({ required: true })}
							/>

							<br />
							<br />

							<label htmlFor="emailInput">{translate("email")} * </label>
							<Input
								id="emailInput"
								value={emailValue}
								onChange={(event) => {
									setEmailValue(event.target.value);
								}}
								ref={register({ required: true })}
							/>

							<br />
							<br />

							<label htmlFor="messageInput">{translate("viesti")} * </label>
							<Textarea
								id="messageInput"
								value={messageValue}
								onChange={(event) => {
									setMessageValue(event.target.value);
								}}
								ref={register({ required: true })}
							></Textarea>

							<br />
							<br />

							<Button type="submit">{translate("laheta")}</Button>
						</form>

						<div style={{ padding: "20px" }}>
							{loading && <p>Loading...</p>}
							{submitError && (
								<p>
									{translate("virhe")} 😕 {translate("yritamyohemmin")}
								</p>
							)}
							{submitSuccess && <p>{translate("kiitos")} 😊</p>}
						</div>
					</React.Fragment>
					
		</Box>
    </>
	);
};


export default ContactTemplate;
