import React from "react";
import { graphql } from 'gatsby';
import Contact from "../../templates/ContactTemplate";

export default function ContactPage({ data: { page } }) {
	return <Contact page={page} locale="en" />;
}

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "contact-form" }) {
			content
			title
            locale {
                locale
            }
			seo {
				metaDesc
				title
			}
		}
	}
`;